










import { Component, Mixins } from 'vue-property-decorator'
import Question from '@/components/organisms/quiz/Question.vue'
import FlipCard from '@/components/organisms/quiz/FlipCard.vue'
import { AnswerStoreType, AnswerType, AnswersRequestType, CurriculumType } from '@/types/quiz/units'
import { TIME_NEXT_QUESTION } from '@/constants'
import UnitQuiz from '@/mixins/quiz/UnitQuiz'
import { QuestionStoreType, QuestionCode } from '@/types/quiz/units'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
@Component({
  components: {
    Question,
    FlipCard,
  },
})
export default class Drill extends Mixins(UnitQuiz, BaseQuiz) {
  private selectAnswer: AnswerType | null = null
  private timesRetry = 0

  private get currentQuestion(): QuestionStoreType {
    return this.$store.getters['question/questionCurrent'] as QuestionStoreType
  }

  private get questionType() {
    switch (this.currentQuestion.answerFormatCode) {
      case QuestionCode.SINGLE_CHOICE:
        return 'question'
      case QuestionCode.FLASHCARD:
        return 'flip-card'
      default:
        return ''
    }
  }

  private gotoOverviewPage() {
    const timeId = setInterval(() => {
      const submitSuccess = this.$store.getters['question/submitSuccess']
      if (submitSuccess === 'pendding') {
        return
      }
      if (submitSuccess) {
        clearInterval(timeId)
        this.push('/quiz/overview', this.$store.getters['question/title'] + '出題', {
          method: 'replace',
          query: { onlyFlashCard: true },
        })
      } else {
        clearInterval(timeId)
        this.goTopPage()
      }
    }, 100)
  }

  private nextPage() {
    const onlyFlashCard = this.$store.getters['question/onlyFlashCard']
    onlyFlashCard ? this.gotoOverviewPage() : this.$router.replace('/quiz/result')
  }

  private chooseAnswer(answer: AnswerType, time?: number) {
    this.selectAnswer = answer
    setTimeout(() => {
      this.selectAnswer = null
      this.$store.dispatch('question/selectAnswer', answer)
      if (this.$store.getters['question/isEnd']) {
        this.submitAnswer()
        this.nextPage()
      }
    }, time || TIME_NEXT_QUESTION)
  }

  private async submitAnswer() {
    const curriculum: CurriculumType = this.getCurriculum()
    const answers: AnswerStoreType[] = this.getAnswerLocalStorage()
    if (curriculum) {
      const input: AnswersRequestType[] = answers.map((answer) => ({
        s_problem_id: answer.sProblemId,
        questions: [
          {
            question_code: answer.questionCode,
            answer: [answer.yourChoose.choiceText],
            answer_format_code: answer.answerFormatCode,
            correct: answer.yourChoose.isCorrectText,
          },
        ],
      }))
      const data = await this.sendAnswers({
        drill_type: curriculum.drillType,
        scope_id: +curriculum.scopeId,
        scope_type: this.levelCurriculum[curriculum.scopeLevel],
        input,
      })
      if (data) {
        this.saveResult(data)
        this.$store.commit('question/setSubmitSuccess', true)
      } else {
        if (this.timesRetry < 1) {
          setTimeout(() => {
            this.submitAnswer()
            this.timesRetry++
          }, 1000)
        } else {
          this.$store.commit('question/setSubmitSuccess', false)
          this.$store.commit('modal/open', 'エラーが発生しました。<br/>学習結果はサーバーに送信されていません。')
        }
      }
    }
  }
}
