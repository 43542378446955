






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class PlayAudioButon extends Vue {
  @Prop() 
  audioUrl!: string

  @Prop()
  customIcon?: boolean

  @Prop()
  reset?: string

  private processing = false
  private audio?: HTMLAudioElement | null

  // 再生中フラグ(現在のインスタンス)
  private playingSelf = false

  // 再生中フラグ(他の音声も含む)
  private get playingAudio(): boolean {
    return this.$store.getters['media/playingAudio']
  }

  private set playingAudio(newVal: boolean) {
    this.playingSelf = newVal
    this.$store.commit('media/setPlayingAudio', newVal)
  }

  private async playAudio(): Promise<void> {
    // 複数回クリック防止
    if (this.processing) return

    // 再生中の処理
    if (this.playingAudio) {
      // インスタンス中のデータを再生中であれば停止する
      if (this.audio && this.playingSelf) {
        this.audio.pause()
        this.playingAudio = false
      }

      return
    }

    this.processing = true

    try {
      if (!this.audio) {
        this.audio = new Audio()
        this.audio.src = this.audioUrl
        this.audio.load()
        this.audio.addEventListener("ended", this.stopAudio)
      }

      await this.audio.play()
      this.playingAudio = true
    } catch (e) {
      console.error('failed to play audio.', e)
      this.playingAudio = false
    } finally {
      this.processing = false
    }
  }

  private beforeDestroy() {
    // 一覧へ戻る場合など、Viewが破棄される際は音声停止
    if (this.audio) {
      this.audio.pause()
      this.playingAudio = false
    }
  }

  private stopAudio() {
    // 同一コンポーネント間の遷移時は再描画が行われない為、音声データ初期化
    if (this.audio) {
      this.audio.pause()
      this.audio = null
      this.playingAudio = false
    }
  }

  @Watch('$route')
  async onChangeRoute(): Promise<void> {
    this.stopAudio()
  }

  @Watch('reset')
  async resetAudio(): Promise<void> {
    this.stopAudio()
  }
}
