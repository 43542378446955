



























import { Component, Mixins, Vue, Prop, Watch } from 'vue-property-decorator'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import { AnswerType, QuestionStoreType } from '@/types/quiz/units'
import { TIME_NEXT_QUESTION } from '@/constants'
import UnitQuiz from '@/mixins/quiz/UnitQuiz'

@Component
export default class FlipCard extends Mixins(BaseQuiz, UnitQuiz) {
  @Prop()
  private question!: QuestionStoreType
  @Prop()
  private submitAnswer!: (question: AnswerType, time: number) => void

  private flip = false

  private clickCheckAnswer = false

  private isSubmit = false

  private checkAnswer() {
    this.clickCheckAnswer = true
    this.flip = true
  }

  private nextQuestion() {
    if (this.isSubmit) {
      return
    }
    this.submitAnswer(this.question.answers[0], TIME_NEXT_QUESTION)
    this.isSubmit = true
    setTimeout(() => {
      this.clickCheckAnswer = false
      this.flip = false
      this.isSubmit = false
    }, TIME_NEXT_QUESTION)
  }
}
